import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import homeItemStyle from './HomeItem.module.scss';

export default class HomeItem extends React.Component {
    state = {
        item: null,
        loading: true
    };

    async componentDidMount() {
        this.setState({ item: this.props.item, loading: false});
    }

    render() {
        return (
            <>   
            {
                this.state.loading || !this.state.item ? (
                    <></>
                ) : (
                    this.state.item.DoubleColumn ? (
                            <div className={homeItemStyle.double + " " + homeItemStyle.listItem}>
                                { 
                                    this.state.item.Title ? (
                                        <h2>{this.state.item.Title}</h2>
                                    ) : ( 
                                        <span className={homeItemStyle.titleSpacer}></span>
                                    ) 
                                }
                                { ReactHtmlParser(this.state.item.DoubleColumn) } 
                            </div>
                    ) : (
                        <div className={homeItemStyle.listItem}>
                            { 
                                this.state.item.Title ? (
                                    <h2>{this.state.item.Title}</h2>
                                ) : ( 
                                    <span className={homeItemStyle.titleSpacer}></span>
                                ) 
                            }
                            { ReactHtmlParser(this.state.item.SingleColumn) } 
                        </div>
                    ) 
                    
                )
            }
            </>  
        );
    } 
}
