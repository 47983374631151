import React from 'react';
import {Helmet} from 'react-helmet';
import Popup from "reactjs-popup";

import landingStyle from './Landing.module.scss';

import SideHeader from '../Base/SideHeader'
import HomeItemList from '../HomeItems/HomeItemList'

import AboutContent from '../AboutPage/AboutContent'

import ContactForm from '../Forms/ContactForm'
import NewsletterForm from '../Forms/NewsletterForm'
import InitialPopupForm from '../Forms/InitialPopupForm'


export default class LandingPageController extends React.Component {
    constructor() {
        super()
        this.state = {
            aboutPageOpen: false,
            aboutfading: false,
            contactPageOpen: false,
            contactfading: false,
            newsletterPageOpen: false,
            newsletterfading: false,
            initialPopupOpen: false,
            initialPopupfading: false
        }
    }

    componentDidMount(){
        if (!sessionStorage["alreadyVisited"]) {
             this.openPage("initialPopupOpen", "initialPopupfading");
             sessionStorage["alreadyVisited"] = true
        }
    }

    openPage(pageName, fadeName) {
        this.setState({ 
            [fadeName]: true,
            [pageName]: true 
        });
    }

    closePage(pageName, fadeName) {
        this.setState({ 
            [pageName]: false,
        });
        setTimeout(() => { 
            this.setState({ [fadeName]: false });
        }, 700);
    }

    render() {
        this.openPage = this.openPage.bind(this)
        this.closePage = this.closePage.bind(this)
        

        if (this.state.aboutPageOpen === false && this.state.aboutfading) {
            document.getElementsByClassName("aboutModel")[0].closest(".popup-overlay").classList.add('out');
        }

        if (this.state.contactPageOpen === false && this.state.contactfading) {
            document.getElementsByClassName("contactModel")[0].closest(".popup-overlay").classList.add('out');
        }

        if (this.state.newsletterPageOpen === false && this.state.newsletterfading) {
            document.getElementsByClassName("newsletterModel")[0].closest(".popup-overlay").classList.add('out');
        }

        if (this.state.initialPopupOpen === false && this.state.initialPopupfading) {
            document.getElementsByClassName("initialPopupModel")[0].closest(".popup-overlay").classList.add('out');
        }
        
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    <title>Focus Print</title>
                    <meta name="Description" content="Publications that combine emerging scholars with artists. Home of the FOCUS On anthology."/>
                    <link rel="canonical" href="https://focusprint.net/" />
                </Helmet>
                <SideHeader openPage={this.openPage}></SideHeader>
                <div className={landingStyle.main}>
                    <HomeItemList/>
                </div>
                <Popup open={ this.state.aboutPageOpen || this.state.aboutfading } closeOnDocumentClick={false} onClose={() => this.closePage("aboutPageOpen", "aboutfading")}
                    modal
                    nested
                >
                    {
                        <div className="aboutModel model">
                            <button className="close" onClick={() => this.closePage("aboutPageOpen", "aboutfading")}>
                                &times;
                            </button>
                            <AboutContent/>
                        </div>
                    }
                </Popup>
                <Popup open={this.state.contactPageOpen || this.state.contactfading} closeOnDocumentClick={false} onClose={() => this.closePage("contactPageOpen", "contactfading")}
                    modal
                    nested
                >
                    {
                        <div className="contactModel model">
                            <button className="close" onClick={() => this.closePage("contactPageOpen", "contactfading")}>
                            &times;
                            </button>
                            <ContactForm closePage={this.closePage}/>
                        </div>
                    }
                </Popup>
                <Popup open={this.state.newsletterPageOpen || this.state.newsletterfading} closeOnDocumentClick={false} onClose={() => this.closePage("newsletterPageOpen", "newsletterfading")}
                    modal
                    nested
                >
                    {
                        <div className="newsletterModel model">
                            <button className="close" onClick={() => this.closePage("newsletterPageOpen", "newsletterfading")}>
                            &times;
                            </button>
                            <NewsletterForm/>
                        </div>
                    }
                </Popup>
                <Popup open={this.state.initialPopupOpen || this.state.initialPopupfading} closeOnDocumentClick={false} onClose={() => this.closePage("initialPopupOpen", "initialPopupfading")}
                    modal
                    nested
                >
                    {
                        <div className="initialPopupModel model">
                            <button className="close" onClick={() => this.closePage("initialPopupOpen", "initialPopupfading")}>
                            &times;
                            </button>
                            <InitialPopupForm/>
                        </div>
                    }
                </Popup>
            </>
        );
    } 
}