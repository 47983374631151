import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';

import LandingPageController from './components/LandingPage/LandingPageController'
import AifaextractPageController from './components/Aifaextract/AifaextractPageController'
import NoMatchPage from './components/Base/NoMatchPage'

import './main.scss';

library.add(faBars, faSpinner, faInstagramSquare, faInstagram)

class App extends Component {

  //constructor() {
    //super();
    // this.state = { 
    //   class: null,
    //   loading: true
    // };
  //}

  // componentDidMount() {
  //   if (!sessionStorage["invertTheme"] || sessionStorage["invertTheme"] === "false") {
  //     this.setState({ class: "light", loading: false });
  //     sessionStorage["invertTheme"] = "true";
  //   } else {
  //     this.setState({ class: "dark", loading: false });
  //     sessionStorage["invertTheme"] = "false";
  //   }
  // }

  render() {
    return (
          // dark theme toggle on refresh
          // <div className={"App " + ( this.state.loading ? null : this.state.class ) }> 
          <div className="App">  
            <div className="mainContainer">
              <BrowserRouter>
                  <Switch>
                      <Route exact path="/" component={LandingPageController} />
                      <Route exact path="/aifaextract" component={AifaextractPageController} />
                      <Route component={NoMatchPage} />
                  </Switch>
              </BrowserRouter>
            </div>
          </div>
    );
  }
}

export default App;