import React from 'react';

import landingStyle from './Landing.module.scss';

import ReactHtmlParser from 'react-html-parser';

export default class landingContent extends React.Component {

    state = {
        pageText: null,
        loading: true,
        url: window._env_.API_URL + "/api/collections/get/FocusPageContent",
        urlError: false
    };

    async componentDidMount() {
        await fetch(this.state.url).then((response) => {
            if (response.status >= 400 && response.status < 600) {
              throw new Error("Bad response from server");
            }
            return response;
        }).then((returnedResponse) => {
            return returnedResponse.json()
        }).then((data) => {
           this.setState({ pageText: data['entries'][1], loading: false});
        }).catch((error) => {
          this.setState({ urlError: true, loading: false});
          console.log(error)
        });
    }

    render() {
        return (
                <div className={landingStyle.landingContainer + " fade"}>
                    {
                       this.state.loading ? (
                            <></>
                        ) : (
                            this.state.urlError | !this.state.pageText ? (
                                <div className={landingStyle.landingContent}>
                                    <h1>FOCUS <span>PRINT</span></h1>
                                    <div className={landingStyle.textBox}>
                                        <p>Could not load page content</p>
                                    </div>
                                </div>
                            ) : (
                                <div className={landingStyle.landingContent}>
                                    <h1 dangerouslySetInnerHTML={{__html: this.state.pageText.Title}}></h1>
                                    <div className={landingStyle.textBox}>
                                        { ReactHtmlParser(this.state.pageText.Body) } 
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
        );
    } 
}