import React from 'react';
import {Helmet} from 'react-helmet';

import AifaextractContent from './AifaextractContent'


export default class AifaextractPageController extends React.Component {
    

    render() {
        
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    <title>Focus Print - Aifaextract</title>
                    <meta name="Description" content="Publications that combine emerging scholars with artists. Home of the FOCUS On anthology."/>
                    <link rel="canonical" href="https://focusprint.net/aifaextract" />
                </Helmet>
                <AifaextractContent/>
            </>
        );
    } 
}