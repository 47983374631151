import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import menuStyle from './Menu.module.scss';


export default class menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: menuStyle.nav
        }
    }

    clickHandler() {
        let lClasses = this.state.classes
        if (this.state.classes.includes(menuStyle.active)) {
            lClasses = lClasses.replace(menuStyle.active, "")
        } else {
            lClasses = lClasses + " " + menuStyle.active
        }
        this.setState({ classes: lClasses});
    }

    render() {
        var navWrapClasses = menuStyle.navWrapper

        if (this.props.menuClass && this.props.menuClass === "darkerBottomShadow") {
            navWrapClasses = menuStyle.navWrapper + " " + menuStyle.darkerBottomShadow;
        }

        return (
            <div className={navWrapClasses}>
                <span className={menuStyle.mobileButton} onClick={this.clickHandler.bind(this)}><FontAwesomeIcon icon={['fas', 'bars']}></FontAwesomeIcon></span>
                <nav className={this.state.classes}>
                    <Link to="//www.focusprint.net/shop" target="_blank" rel="noreferrer" aria-label="focusprint shop"><span>Shop</span></Link>
                    <button onClick={() => this.props.openPage("aboutPageOpen", "aboutfading")}><span>About</span></button>
                    <button onClick={() => this.props.openPage("newsletterPageOpen", "newsletterfading")}><span>Newsletter</span></button>
                    <button onClick={() => this.props.openPage("contactPageOpen", "contactfading")}><span>Contact</span></button>
                    <Link className={menuStyle.instagram} aria-label="Instagram" rel="noreferrer" target="_blank" to="//www.instagram.com/focusanthology/"><FontAwesomeIcon icon={['fab', 'instagram']} /></Link>
                </nav>
            </div>
        );
    }
};