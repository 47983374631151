import React from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

import notfoundimage from '../../images/404_image.png';

import noMatchStyle from './NoMatch.module.scss';

const noMatchPage = (props) => {
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <title>Focus Print - 404</title>
            </Helmet>
            <div className={noMatchStyle.noMatch}>
                <h1>FOCUS</h1>
                <img src={notfoundimage} alt="404 not found"/>
                <h2>404 - Page not found</h2>
                <p>Would you like to go <Link to="/">home</Link>?</p>
            </div>
        </React.Fragment>   
    );
};

export default noMatchPage;