import React, { Component } from 'react';

import InitialPopupContent from '../InitialPopupPage/InitialPopupContent'

import contactStyle from './Contact.module.scss';

class InitialPopupForm extends Component {

    render() {
        return (
            <div className={contactStyle.formWrap + " " + contactStyle.initialForm}>
                <InitialPopupContent/>
                <form action="https://gmail.us17.list-manage.com/subscribe/post?u=a24c72f3c0f827970bd9012ce&amp;id=34e3bf0c21" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
                    <label htmlFor="mce-EMAIL">Email</label>
                    <input placeholder="Email *" type="email" name="EMAIL" className="required email" id="mce-EMAIL"/>
                    <button className={contactStyle.button} type="submit">Subscribe</button>
                </form>
                 <p className={contactStyle.campaigns}><a href="https://us17.campaign-archive.com/home/?u=a24c72f3c0f827970bd9012ce&id=34e3bf0c21" title="View previous campaigns">View previous campaigns.</a></p>
            </div>
        );
    } 
}

export default InitialPopupForm;