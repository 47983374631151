import React, { Component } from 'react';
// import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HorizontalScroll from 'react-scroll-horizontal'

import homeItemStyle from './HomeItem.module.scss'

import HomeItem from './HomeItem'

class HomeItems extends Component {
    state = {
        homeItems: null,
        loading: true,
        url: window._env_.API_URL + "/api/collections/get/FocusHomeItems",
        urlError: false,
        removeHoriScroll: false
    };
    
    async componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        await fetch(this.state.url).then((response) => {
            if (response.status >= 400 && response.status < 600) {
              throw new Error("Bad response from server");
            }
            return response;
        }).then((returnedResponse) => {
            return returnedResponse.json()
        }).then((data) => {
           this.setState({ homeItems: data['entries'], loading: false});
        }).catch((error) => {
          this.setState({ urlError: true, loading: false});
          console.log(error)
        });
    }
    
    resize() {
        this.setState({removeHoriScroll: window.innerWidth <= 767});
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

  render() {
    return (
        <div className={homeItemStyle.homeItems}>
            {
               this.state.loading ? (
                    <></>
                ) : ( 
                    this.state.urlError | !this.state.homeItems ? (
                        <>
                            <h2>Error</h2>
                            <p>Could not load page content</p>
                        </>
                    ) : (
                        <>
                        {
                            this.state.removeHoriScroll ? (
                                // slice to limit 3 items on mobile
                                <>
                                    {this.state.homeItems.slice(0, 2).map((item, index) => 
                                        <HomeItem item={item} key={index}/>
                                    )}
                                </>
                            ) : (
                                <HorizontalScroll reverseScroll = { true }>
                                    {this.state.homeItems.map((item, index) => 
                                        <HomeItem item={item} key={index}/>
                                    )}
                                </HorizontalScroll>
                            )
                        }   
                    </>
                    )
                )
            }
        </div>
    );
  }
}

export default HomeItems;
