import React from 'react';
import Menu from './Menu'

import LandingContent from '../LandingPage/LandingContent';
import sideHeaderStyle from './SideHeader.module.scss';

const sideHeader = (props) => {

    return (
        <header className={sideHeaderStyle.home}>
            <LandingContent/>
            <Menu openPage={props.openPage} ></Menu>
        </header>
    );
};

export default sideHeader;
