import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import contactStyle from './Contact.module.scss';

export default class ContactContent extends React.Component {

    state = {
        pageText: null,
        loading: true,
        url: window._env_.API_URL + "/api/collections/get/FocusPageContent",
        urlError: false
    };

    async componentDidMount() {
        await fetch(this.state.url).then((response) => {
            if (response.status >= 400 && response.status < 600) {
              throw new Error("Bad response from server");
            }
            return response;
        }).then((returnedResponse) => {
            return returnedResponse.json()
        }).then((data) => {
           this.setState({ pageText: data['entries'][2], loading: false});
        }).catch((error) => {
          this.setState({ urlError: true, loading: false});
          console.log(error)
        });
    }

    render() {
        return (
            <div className={contactStyle.contactContent + " fade"}>
                {
                    this.state.urlError | !this.state.pageText ? (
                        <>
                            <h2>Error</h2>
                            <p>Could not load page content</p>
                        </>
                    ) : (
                        <React.Fragment>
                            <h2>{ this.state.pageText.Title }</h2>
                            { ReactHtmlParser(this.state.pageText.Body) } 
                        </React.Fragment>
                    )
                }
            </div>
        );
    } 
}